import { Image, Button } from "@chakra-ui/react";
import React from "react";
import ButtonIcon from "../../images/button_icon.svg";
import InstagramIcon from "../../images/instagram_icon.svg";
import { COLORS_PRIMARY } from "./opalite_colors";
import InstagramSocialsIcon from "../../images/social_icons/button_icon_instagram_circle.svg";
import TikTokSocialsIcon from "../../images/social_icons/button_icon_tik_tok_circle.svg";

interface OpaliteButtonProps {
  children: React.ReactNode;
  margin?: string | object;
  marginTop?: string | object;
  marginBottom?: string | object;
  marginLeft?: string | object;
  marginRight?: string | object;
  padding?: string | object;
  paddingTop?: string | object;
  paddingBottom?: string | object;
  paddingLeft?: string | object;
  paddingRight?: string | object;
  fontSize?: string | object;
  onClick?: Function;
}

export const OpaliteButtonStandard: React.FC<OpaliteButtonProps> = (props) => {
  return (
    <Button
      backgroundColor="rgb(0,0,0,0)"
      color={COLORS_PRIMARY.FONT}
      borderWidth="2px"
      {...props}
    >
      {" "}
      {props.children} <Image marginLeft="1rem" src={ButtonIcon} />
    </Button>
  );
};

export const OpaliteButtonLogin: React.FC<OpaliteButtonProps> = (props) => {
  return (
    <Button
      backgroundColor="rgb(0,0,0,0)"
      color={COLORS_PRIMARY.FONT}
      borderWidth="2px"
      {...props}
    >
      {" "}
      {props.children} <Image marginLeft="1rem" src={InstagramIcon} />
    </Button>
  );
};

export const OpaliteButtonInput: React.FC<OpaliteButtonProps> = (props) => {
  return (
    <Button
      backgroundColor="#1b1b1b"
      borderWidth="2px"
      color={COLORS_PRIMARY.FONT}
      width="25%"
      borderRadius="0 5px 5px 0"
      {...props}
    >
      {" "}
      {props.children}
    </Button>
  );
};

const toggleLoginWithSocialIcon = (iconType: string) => {
  switch (iconType) {
    case "instagram":
      return InstagramSocialsIcon;
    case "tik tok":
      return TikTokSocialsIcon;
    default:
      return "";
  }
};

export const OpaliteButtonLoginWithSocial: React.FC<OpaliteButtonProps> = (
  props
) => {
  return (
    <Button
      backgroundColor="#1b1b1b"
      borderWidth="2px"
      color={COLORS_PRIMARY.FONT}
      width="100%"
      borderRadius="20px"
      borderLeftWidth="0"
      {...props}
    >
      <Image
        position="absolute"
        left="0"
        src={toggleLoginWithSocialIcon(props.iconType)}
        height="100%"
      />{" "}
      {props.children}
    </Button>
  );
};
